
// values
import {Logo_white, Background, Icon} from '../../values';

const LoadingScreen = () => {
    return (
        <div className="container_loading_screen">
            <img alt="" className="background_loading_screen" src={Background} />
            <img alt="Hera" title="Hera" src={Icon} style={{width:80}} />
        </div>
    );
};

export default LoadingScreen;