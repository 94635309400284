import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunkMiddleware  from 'redux-thunk';

import Menu from './reducers/Menu';


const reducers = combineReducers({
    Menu
})

const store = createStore(
    reducers,
    compose(
        applyMiddleware(thunkMiddleware),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() || compose
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
)

export default store;