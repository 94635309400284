import {reduxState, stateData} from '../../values';

const initialState = {
    categories: [],
    products:   [],
    menu:       '',
    submenu:    '',
};

const reducer = (state = initialState, action) =>{
    if(action.type === reduxState.get_menu){
        return {
            ...state,
            categories: action.categories,
            products:   action.products,
            status:     action.status,
        }
    }
    if(action.type === reduxState.change_menu){
        return {
            ...state,
            menu: action.menu,
        }
    }
    return state
}
export default reducer;