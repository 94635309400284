import {formatMoney}    from '../../utils';

import {Product1, Product2} from '../../values';

const RowV2 = ({data, setDataModal}) => {
    return (
        <div style={{marginTop:20, marginBottom:5, paddingTop:20, width:'100%'}}>
            <div onClick={()=>setDataModal(data)} style={{display:'flex', alignItems:'center'}}>
                <div style={{position:'relative'}}>
                    <img alt='' src={data.image} className='image_row1' />
                    <img alt='' src={data.image2} className='image_row2' />
                </div>
                <div>
                    <p style={{margin:0, fontSize:18, fontFamily:'Lucida'}}>{data.name}</p>
                    <p style={{margin:0, fontFamily:'Lucida'}}>{formatMoney(data.price)}</p>
                </div>
            </div>
        </div>
    );
};

export default RowV2;