import {useState, useEffect}        from 'react';
import {connect}                    from 'react-redux';
import clsx                         from 'clsx';
import {useHistory, useLocation}    from 'react-router-dom';
import {makeStyles, withStyles}     from '@material-ui/core/styles';
import {Drawer, AppBar, List, CssBaseline, Tooltip, Container} from '@material-ui/core';
// Redux
import {changeMenu} from '../../redux/actions/Menu';

// Values
import {Icon, Background, Logo_black}   from '../../values';
import {generateUniqueKey}              from '../../utils';

const CustomTooltip = withStyles((theme) =>({
    tooltip:{
        background:'rgba(0, 0, 0, 0.8)',
        width:120,
        height:30,
        borderRadius:9,
        color:'#fff',
        fontSize:16,
        textAlign:'center',
        fontFamily:'regular'
    }
}))(Tooltip)

const drawerWidth = 50;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        // backgroundColor:'#fff',
    },
    appBar: {
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.down('xl')]: {
            zIndex:-10,
        },
        // [theme.breakpoints.down('xs')]: {
        //     zIndex:100,
        // },
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerClose: {
        width: drawerWidth,
        borderRight:0,
        // background:'#bed9cf',
        backgroundColor:'#bed9cf',
        // backgroundImage:`url(${Background})`,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        [theme.breakpoints.down('xl')]: {
            marginLeft:'37%',
            // maxHeight:'77.5%',
            // marginTop:90,
            maxHeight:'100%',
            marginTop:0,
        },
        [theme.breakpoints.down('lg')]: {
            marginLeft:'32%',
            // maxHeight:'77.5%',
            // marginTop:90,
            maxHeight:'100%',
            marginTop:0,
        },
        [theme.breakpoints.down('md')]: {
            marginLeft:'25%',
            // maxHeight:'80%',
            // marginTop:160,
            maxHeight:'100%',
            marginTop:0,
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft:'22%',
            // maxHeight:'68%',
            // marginTop:140,
            maxHeight:'100%',
            marginTop:0,
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft:0,
            maxHeight:'100%',
            marginTop:0,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // marginTop:30,
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        background:"#fff",
        // minHeight:'100vh'
    },
    listScroll:{
        overflowY:'auto',
        overflowX:'hidden',
        // overflowY:'hidden',
        height:'100%',
        scrollbarWidth:'none',
        scrollbarColor:'#CDCDCD80 #f0f0f0',
        '&::-webkit-scrollbar':{
            display: 'none',
            // height:6,
            // width:6,
            // background: '#f0f0f0',
        },
        '&::-webkit-scrollbar-thumb':{
            background: '#cdcdcdcc',
        }
    },
    button_open:{
        minWidth:150,
        marginLeft:-15,
        cursor:'pointer',
        paddingTop:10,
        paddingBottom:10
    },
    button_close:{
        minWidth:150,
        marginLeft:70,
        cursor:'pointer',
        paddingTop:10,
        paddingBottom:10
    },
    menu_selected:{
        background:'#4aa3ff', 
        width:50, 
        height:50, 
        borderRadius:12, 
        display:'flex', 
        marginTop:20,
        justifyContent:'center', 
        alignItems:'center', 
        cursor:'pointer',
        userSelect:'none',
        '&:active':{
            transform: 'scale(0.95)'
        }
    },
    menu_unselected:{
        width:50, 
        height:50, 
        borderRadius:12, 
        display:'flex', 
        marginTop:20,
        justifyContent:'center', 
        alignItems:'center', 
        cursor:'pointer',
        userSelect:'none',
        '&:active':{
            transform: 'scale(0.95)',
            background:'#4aa3ff', 
        }
    },
    container_appbar:{
        backgroundColor:'#bed9cf',
        zIndex:0,
        [theme.breakpoints.down('xl')]: {
            backgroundColor:'#bed9cf',
        },
        [theme.breakpoints.down('xs')]: {
            backgroundColor:'#fff',
        },
    },
    bar_space:{
        position:'absolute', 
        width:'100vw', 
        left:0, 
        backgroundColor:'#bed9cf', 
        height:200, 
        zIndex:0,
        [theme.breakpoints.down('xl')]: {
            display:'block'
        },
        [theme.breakpoints.down('xs')]: {
            display:'none'
        },
    }
}));


const Menu = ({products, categories, title_menu, changeMenu}) => {
    const classes           = useStyles();
    const location          = useLocation(); 
    const history           = useHistory();

    const [open, setOpen]               = useState(false)
    const [locationView, setLocation]   = useState(location.pathname.split('/')[1])
    const [version]                     = useState('V 1.0.6')

    const changeTitleMenu = (title) => {
        changeMenu(title)
        window.scrollTo(0, 0);
    }

    const calcHeight = (name) =>{
        let height = 130
        if(name.length>=5){
            height = 130
        }
        if(name.length>=10){
            height = 200
        }
        if(name.length>=15){
            height = 280
        }
        if(name.length>=20){
            height = 370
        }
        return height
    }

    useEffect(()=>{
        let aux = ''
        categories.map((item, index)=>{
            if(index===0){
                aux = item.name
            }
        })
        changeTitleMenu(aux)
    },[categories])
    return (
        <div>
            <div className={classes.root}>
                <CssBaseline />

                {/* <AppBar
                    style={{background:'white', boxShadow:' 0px 0px 0px 0px rgba(0,0,0,0.75)'}}
                    position="fixed"
                    className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                    })} >
                        <div className={classes.container_appbar}>
                            <Container>
                                <div style={{display:'flex', justifyContent:'center', marginTop:20, marginLeft:42}}>
                                    <img alt="Hera" title="Hera" src={Logo_black} style={{width:120}} />
                                </div>
                            </Container>
                        </div>
                </AppBar>
                <div className={classes.bar_space} /> */}
                
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                    })}
                    classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                    }} >
                    
                    <div className={classes.listScroll} id="scroll">
                        <div>
                            <div style={{display:'flex', justifyContent:'flex-end'}}>
                                <label style={{textAlign:'right', paddingRight:0, color:'#bed9cf'}}>{version}</label>
                            </div>
                            <div style={{display:'flex', justifyContent:'center'}}>
                                <CustomTooltip title="" placement="right">
                                    <img alt="" src={Icon} style={{width:35}} />
                                </CustomTooltip>
                            </div>
                        </div>
                        <div style={{height:'100%', display:'flex', justifyContent:'center', marginTop:50}}>
                            <List>
                                {
                                    categories&&
                                    categories.map(i=>(
                                        <div onClick={()=>changeTitleMenu(i.name)} key={generateUniqueKey()}
                                            style={{height:calcHeight(i.name), display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer'}}>
                                            <p style={{transform:'rotate(-90deg)', color:title_menu===i.name?'#000':'#fff', fontSize:20, userSelect:'none', fontFamily:'Lucida'}}>
                                                {i.name}
                                            </p>
                                        </div>
                                    ))
                                }
                            </List>
                        </div>
                    </div>
                </Drawer>

            </div>
        </div>
    );
};

const mapStateToProps = state =>({
    products:       state.Menu.products,
    categories:     state.Menu.categories,
    title_menu:     state.Menu.menu
})

export default connect(mapStateToProps, {changeMenu})(Menu)

