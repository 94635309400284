import ScrollMenu   from 'react-horizontal-scrolling-menu';
import {Animated}   from 'react-animated-css'
import {Container}  from '@material-ui/core';

import {Logo_black}   from '../../values';

const Navbar = ({submenu, value, setValue}) => {
    return (
        <div style={{position:'fixed', background:'#fff'}}>
            <div style={{display:'flex', justifyContent:'center', marginTop:10, marginBottom:20, marginLeft:42}}>
                <img alt="Hera" title="Hera" src={Logo_black} style={{width:120}} />
            </div>
            <Animated animationIn='fadeIn' animationOut='fadeOut' >
                <div className='container_submenu'>
                    {
                        submenu.map((item, index) =>
                        <button onClick={()=>setValue(item)}
                            className={value===item?'button_select':'button_default'} style={{fontFamily:'Lucida'}}>{item}</button>
                        )
                    }
                </div>
            </Animated>
            <div style={{borderBottom:'1px solid #002662', width:'100%', marginTop:10, opacity:0.10}} />
        </div>
    );
};

export default Navbar;