import background   from '../images/background.svg';
import icon         from '../images/icon.svg';
import logo_black   from '../images/logo_black.svg';
import logo_white   from '../images/logo_white.svg';
import ic_facebook  from '../images/ic_facebook.svg';
import ic_whatsapp  from '../images/ic_whatsapp.svg';
import ic_instagram from '../images/ic_instagram.svg';
import phone_mask   from '../images/phone_mask.svg';
import base         from '../images/base.svg';
import phone_square from '../images/phone_square.png';

import p1           from '../images/p1.jpg';
import p2           from '../images/p2.jpg';

export const Background     = background
export const Icon           = icon
export const Logo_black     = logo_black
export const Logo_white     = logo_white
export const Ic_facebook    = ic_facebook
export const Ic_whatsapp    = ic_whatsapp
export const Ic_instagram   = ic_instagram
export const Phone_mask     = phone_mask
export const Base           = base
export const Phone_square   = phone_square

export const whatsapp_number = '+5213339498862'

export const Product1 = p1
export const Product2 = p2

//
// ─── ACTIONS ────────────────────────────────────────────────────────────────────
//
export const stateData = {
    state1:'DOWNLOADING',
    state2:'DOWNLOADED'
}

//
// ─── VIEW ───────────────────────────────────────────────────────────────────────
//
export const StateView = {
    state1: "VIEW_DATA",
    state2: "CREATE_DATA",
    state3: "EDIT_DATA",
    state4: "DELETE_DATA"
}

//
// ─── STATE OF REDUX ─────────────────────────────────────────────────────────────
//
export const reduxState = {
    drawer:                 'UPDATE_DRAWER',
    view:                   'CHANGE_VIEW',
    get_menu:               'GET_MENU',
    update_menu:            'UPDATE_MENU',
    change_menu:            'CHANGE_MENU',
}