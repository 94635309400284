import {formatMoney}    from '../../utils';

const Options = ({data, open, setOpen, setDataModal}) => {
    return (
        <div style={{marginTop:10, marginBottom:0, paddingTop:15, width:'100%'}}>
            <div onClick={()=>setDataModal(data)}>
                <p style={{margin:0, fontSize:18, fontFamily:'Lucida'}}>{data.name}</p>
                {/* <p style={{margin:0, fontFamily:'Lucida'}}>{`$ ${formatMoney(data.price)} MXN`}</p> */}
                <div className="scroll_options">
                    <div style={{display:'flex', alignItems:'center', marginTop:10}}>
                        {
                            data.options.map(i=>(
                                <div style={{textAlign:'center', marginRight:20}}>
                                    <img alt='' src={i.image} style={{width:100, height:100, objectFit:'cover'}} />
                                    <p style={{margin:0, fontFamily:'Lucida'}}>{i.title}</p>
                                    <p style={{margin:0, fontFamily:'Lucida', fontSize:13}}>{formatMoney(i.price)}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Options;