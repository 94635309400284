
export const emailformat = /^([\w.-]+)@([\w-]+)((\.(\w){2,3})+)$/;
export const phoneformat = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const formatMoney = (amount, decimalCount = 0, decimal = ".", thousands = ",") =>{
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
        const negativeSign = amount < 0 ? "-" : "";
        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;
        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (error) {
        console.log(error)
    }
}

export const formatDate1 = (date) =>{
    // https://medium.com/swlh/use-tolocaledatestring-to-format-javascript-dates-2959108ea020
    // const dateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric', second:'numeric' }
    const dateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric', minute:'numeric'}
    const fecha = new Date(date).toLocaleDateString('es-MX', dateOptions)
    return fecha.replace('de',' ').toUpperCase()
    // return fecha.replace('de',' ').charAt(0).toUpperCase() + fecha.slice(1)
}

export const formatDate2 = (date) =>{
    const dateOptions = {year: 'numeric', month: 'long', day: 'numeric'}
    const fecha = new Date(date).toLocaleDateString('es-MX', dateOptions)
    return fecha.replace('de',' ').toUpperCase()
    // return fecha.replace('de',' ').charAt(0).toUpperCase() + fecha.slice(1)
}

export const formatDate3 = (date) =>{
    const dateOptions = {year: 'numeric', month: 'short', day: 'numeric'}
    const fecha = new Date(date).toLocaleDateString('es-MX', dateOptions)
    return fecha
    // return fecha.replace('de',' ').charAt(0).toUpperCase() + fecha.slice(1)
}

export const formatDate4 = (date) =>{
    // const dateOptions = {hour:'numeric', minute:'numeric', second:'numeric'}
    // const fecha = new Date(date).toLocaleDateString('es-MX', dateOptions)
    let hours       = new Date(date).getHours()
    let minutes     = new Date(date).getMinutes()
    let ampm        = hours >= 12 ? 'pm' : 'am'
    hours           = hours % 12
    hours           = hours ? hours : 12
    minutes         = minutes < 10 ? '0'+minutes : minutes
    const fecha     = hours + ':' + minutes + ' ' + ampm

    return fecha
}

export const generateUniqueKey = (count=3) =>{
    let characters  = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let date        = new Date()
    let res         = ''

    for (let i = 0; i < count; i++) {
        res += characters[Math.floor(Math.random() * characters.length)]
    }
    return `${res}${date.getTime()}`
}
