import {stateData, reduxState} from '../../values';

export const getMenu = (categories, products) =>{
    return(dispatch) =>{
        dispatch({
            type:       reduxState.get_menu,
            categories: categories,
            products:   products,
            status:     stateData.state2,
        })
    } 
}

export const changeMenu = (menu) =>{
    return(dispatch) =>{
        dispatch({
            type:   reduxState.change_menu,
            menu:   menu,
        })
    } 
}