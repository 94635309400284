
export const routesApi = {
    sandbox:    'http://localhost:5000/menuonline-a59fa/us-central1/api/',
    productive: 'https://us-central1-menuonline-a59fa.cloudfunctions.net/api/'
}

export const urlApi = routesApi.productive

export const urlPath = {
    menu: 'menu',
}


export const get = async(url, token, extra='') =>{
    let data = [];
    let flag = false
    // console.log("URL: ", url+extra);
    const request = {
        method:"GET",
        headers:{
            'Authorization': 'Bearer ' + token,
        }
    }
    await fetch(url+extra, request)
    .then(async(res) =>{
        if(res.status === 200){
            flag = true
            console.log(res.status);
            return res.json()
        } else{
            // console.log(res);
            // if(res.status === 401){
            //     await refreshToken()
            // }
        }
    })
    .then((response) =>{
        if(flag === true){
            data = response 
        }
    })
    .catch(() =>{
        flag = false
    })
    return {data: data, flag: flag}
}