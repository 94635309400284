import {formatMoney}    from '../../utils';

const Row = ({data, open, setOpen, setDataModal}) => {
    return (
        <div style={{marginTop:20, marginBottom:5, paddingTop:20, width:'100%'}}>
            <div onClick={()=>setDataModal(data)} style={{display:'flex', alignItems:'center'}}>
                <img alt='' src={data.image} style={{width:100, height:100, marginRight:10, objectFit:'scale-down'}} />
                <div>
                    <p style={{margin:0, fontSize:18, fontFamily:'Lucida'}}>{data.name}</p>
                    <p style={{margin:0, fontFamily:'Lucida'}}>{formatMoney(data.price)}</p>
                </div>
            </div>
        </div>
    );
};

export default Row;