import {formatMoney}    from '../../utils';

const Text = ({data, open, setOpen, setDataModal}) => {
    return (
        <div className='Text'>
            <div>
                <p style={{margin:0, fontSize:30, fontFamily:'snell'}}>{data.name}</p>
                <p style={{margin:0, fontFamily:'Lucida'}}>{formatMoney(data.price)}</p>
            </div>
        </div>
    );
};

export default Text;