import {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import {connect}    from 'react-redux';
// Redux
import {getMenu}    from './redux/actions/Menu';

import './App.css';
import './css/LoadingScreen.css';
import './css/SubMenu.css';
import './css/Fonts.css';
import './css/Phone.css';
import './css/Text.css';

import ScrollToTop      from './components/global/ScrollToTop';
import LoadingScreen    from './components/global/LoadingScreen';
import Menu             from './components/global/Menu';
import Info             from './components/menu/Menu';


// Api
import {getData} from './utils/menu';

const Default = () =>{
    return(
        <div></div>
    )
}

function App({getMenu}) {
    const [loading, setLoading] = useState(false)

    const GetData = async() =>{
        const extra = ``
        const res = await getData('', extra)
        getMenu(res.data.categories, res.data.products)
    }

    useEffect(() =>{
        GetData()
        setTimeout(()=> 
            setLoading(true)
        , 3000)
    })
    return (
        <div style={{background:'#fff'}}>
            <Router>
                {
                    loading?
                    <>
                        <Menu />
                        <Route exact path="/" component={Info} />
                    </>
                    :
                    <>
                        <Route exact path="/" component={LoadingScreen} />
                    </>
                }
                <Route path="*">
                    <Default />
                </Route>
            </Router>
        </div>
    );
}

const mapStateToProps = state =>({
    stateView:          state.Global.stateView,
})

export default connect(null, {getMenu})(App)

