import {useState, useEffect, createRef}    from 'react';
import {Container, Grid}        from '@material-ui/core';
import {connect}                from 'react-redux';
import {makeStyles}             from '@material-ui/core/styles';

import Navbar       from '../global/Navbar';
import Text         from './Text';
import Row          from './Row';
import RowAnimated  from './RowV2';
import Options      from './Options';
import Modal        from '../global/ModalGlobal';
import ModalAux     from '../global/ModalAux';

import {formatMoney}        from '../../utils';
import {Logo_white, Phone_square, Logo_black, Background}   from '../../values';

const useStyles = makeStyles((theme) => ({
    container:{
        width:'100%', 
        height:'100vh',
        [theme.breakpoints.down('xl')]: {
            backgroundColor:'#bed9cf',
            // backgroundImage:`url(${Background})`,
            // backgroundSize:'1000px 2000px',
        },
        [theme.breakpoints.down('xs')]: {
            backgroundColor:'#fff',
        },
    },
    container_data:{
        position:'absolute', 
        top:0, 
        left:50, 
        background:'#fff',
        zIndex:2,
        overflowY:'auto',
        overflowX:'hidden',
        scrollbarWidth:'none',
        paddingLeft:10,
        height:'100%',
        width:455,
        paddingBottom:20,
        [theme.breakpoints.down('xl')]: {
            marginLeft:'37vw',
            // maxHeight:'82%',
            maxHeight:'100%',
        },
        [theme.breakpoints.down('lg')]: {
            marginLeft:'32vw',
            // maxHeight:'82%',
            maxHeight:'100%',
        },
        [theme.breakpoints.down('md')]: {
            marginLeft:'25vw',
            // maxHeight:'80%',
            maxHeight:'100%',
            // top:160,
            top:0,
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft:'24vw',
            // maxHeight:'68%',
            maxHeight:'100%',
            // top:140,
            top:0,
            left:32,
            width:400,
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft:0,
            maxHeight:'100%',
            top:0,
            width:'auto',
            left:50,
            paddingBottom:60,
        },
        '&::-webkit-scrollbar':{
            display: 'none',
        },
        '&::-webkit-scrollbar-thumb':{
            background: '#cdcdcdcc',
        }
    },
    container_navbar:{
        position:'absolute', left:-5,
        zIndex:2,
        [theme.breakpoints.down('xl')]: {
            paddingTop:0,
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop:0,
        },
    },
    logo:{
        position:'absolute',
        right:50,
        top:10,
        [theme.breakpoints.down('md')]: {
            right:20,
        },
        [theme.breakpoints.down('xs')]: {
            display:'none'
        },
    },
    rectangle_white:{
        background:'#bed9cf', width:52, 
        // height:'82%', 
        height:'100%', 
        position:'absolute',
        marginLeft:'37vw', 
        // top:50,
        top:0, 
        left:-1, zIndex:10,
        boxShadow:'0 10px 40px 0 rgba(0,0,0,0.25)',

        [theme.breakpoints.down('xl')]: {
            marginLeft:'37vw',
        },
        [theme.breakpoints.down('lg')]: {
            marginLeft:'32vw',
        },
        [theme.breakpoints.down('md')]: {
            // top:160,
            // maxHeight:'80%',
            marginLeft:'25vw',
            top:0,
            maxHeight:'100%',
            // display:'none'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft:'24vw',
            // maxHeight:'68%',
            // top:140,
            maxHeight:'100%',
            top:0,
            left:-16,
        },
        [theme.breakpoints.down('xs')]: {
            display:'none'
        },
    }
}));

const Menu = ({products, categories, title_menu}) => {
    const classes           = useStyles();
    const ref               = createRef()

    const [submenu, setSubMenu]     = useState([])
    const [value, setValue]         = useState('')
    const [data, setData]           = useState([])
    const [open, setOpen]           = useState(false)
    const [openAux, setOpenAux]     = useState(false)
    const [dataModal, setDataModal] = useState('')
    const [lenguage, setLenguage]   = useState('ES')

    const openModal = (data) => {
        setDataModal(data)
        setOpen(true)
    }

    const openModal2 = (data) => {
        setDataModal(data)
        setOpenAux(true)
    }

    const filterData = (val) =>{
        ref.current.scrollTop = 0
        setValue(val)
        let aux     = []
        products.forEach(i=>{
            if(i.subcategory===val && i.category===title_menu){
                aux.push(i)
            }
        })
        aux = aux.sort((a,b) =>{
            return a.position - b.position
        })

        setData(aux)
    }
    
    const mapData = () =>{
        let aux     = []
        let aux2    = []
        let valueDefault = ''
        categories.forEach(i=>{
            if(i.name===title_menu){
                aux = i.subcategory
            }
        })
        if(aux){
            valueDefault = aux[0]
            setValue(aux[0])
            setSubMenu(aux)

            products.forEach(i=>{
                if(i.subcategory===valueDefault && i.category===title_menu){
                    aux2.push(i)
                }
            })

            aux2 = aux2.sort((a,b) =>{
                return a.position - b.position
            })
            
            setData(aux2)
        }
    }

    useEffect(()=>{
        mapData()
        ref.current.scrollTop = 0
    },[title_menu])
    return (
        <div className={classes.container}>
            {/* <img alt='Hera' src={Logo_white} className={classes.logo} /> */}
            {/* <img alt='' src={Phone_square} className='phone' /> */}
            <div className={classes.rectangle_white} />
            <div className={classes.container_data} ref={ref}>
                    <div className={classes.container_navbar}>
                        <Navbar submenu={submenu} value={value} setValue={filterData} />
                    </div>
                    <div style={{height:80}} />
                    <Modal data={dataModal} open={open} setOpen={setOpen} lenguage={lenguage} setLenguage={setLenguage} />
                    <ModalAux data={dataModal} open={openAux} setOpen={setOpenAux} lenguage={lenguage} setLenguage={setLenguage} />
                    <br/>
                    <div>
                        <Grid container>
                            {
                                data&&
                                data.map(i=>(
                                    i.style==='Texto'?
                                        <Text data={i} open={open} setOpen={setOpen} dataModal={dataModal} setDataModal={openModal} />
                                    :
                                    i.style==='Fila'?
                                        <Row data={i} open={open} setOpen={setOpen} dataModal={dataModal} setDataModal={openModal} />
                                    :
                                    i.style==='Opciones'?
                                        <Options data={i} open={open} setOpen={setOpen} dataModal={dataModal} setDataModal={openModal} />
                                    :
                                    i.style==='Fade'?
                                        <RowAnimated data={i} setDataModal={openModal2} />
                                    :
                                        <Grid item xs={6}>
                                            <Container style={{marginTop:30, marginBottom:30, marginLeft:-7}} onClick={()=>openModal(i)}>
                                                <img alt='' src={i.image} style={{width:'100%', height:100, marginRight:10, objectFit:'scale-down'}} />
                                                <div style={{textAlign:'center'}}>
                                                    <p style={{margin:0, fontWeight:600, fontFamily:'Lucida'}}>{i.name}</p>
                                                    <p style={{margin:0, fontFamily:'Lucida'}}>{formatMoney(i.price)}</p>
                                                </div>
                                            </Container>
                                        </Grid>
                                ))
                            }
                        </Grid>
                    </div>
            </div>
        </div>
    );
};

const mapStateToProps = state =>({
    products:           state.Menu.products,
    categories:         state.Menu.categories,
    title_menu:         state.Menu.menu
})

export default connect(mapStateToProps, {})(Menu)