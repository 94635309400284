import {Container, Modal}   from '@material-ui/core';

import {Ic_facebook, Ic_whatsapp, whatsapp_number, Ic_instagram}   from '../../values';
import {generateUniqueKey, formatMoney}  from '../../utils';


const ModalAux = ({data, open, setOpen, lenguage, setLenguage}) => {
    const openFacebook = (data) =>{
        window.location.href = `http://www.facebook.com/sharer.php?u=https://menuonline-a59fa.firebaseapp.com/`
    }
    const openInstragram = (data) =>{
        window.location.href = `https://www.instagram.com/heracoffeetea/`
    }
    const openWhatsapp = () =>{
        window.location.href = `https://wa.me/${whatsapp_number}`
    }
    return (
        <div>
            <Modal open={open} 
                style={{display:'flex', alignItems:'center', justifyContent:'center',}}
                onClose={()=>setOpen(false)}>
                    
                    <Container maxWidth="sm">
                        <div style={{background:'white', minHeight:150, borderRadius:7, position:'relative'}}>
                            <div className='container_close' onClick={()=>setOpen(false)}>
                                <p style={{margin:0, fontSize:16}}>{'X'}</p>
                            </div>
                            <br/>
                            <Container>
                                <div style={{paddingTop:10}}>
                                    <div style={{position:'relative'}}>
                                        <img alt='' src={data.image} className='image_modal_row1' />
                                        <img alt='' src={data.image2} className='image_modal_row2' />
                                    </div>

                                    <p style={{margin:0, fontFamily:'Lucida', fontSize:17}}>{data.name}</p>
                                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:7, marginBottom:7}}>
                                        <div>
                                            <label style={{fontFamily:'snell', fontSize:22, display:lenguage==='ES'?'inline':'none'}}> { data.vegan?'Vegano':'' } </label>
                                            <label style={{display:lenguage==='ES'?'inline':'none'}}>{data.vegan&&data.spicy?'-':''}</label>
                                            <label style={{fontFamily:'snell', fontSize:22, display:lenguage==='ES'?'inline':'none'}}> { data.spicy?'Picante':'' } </label>
                                            <label style={{display:lenguage==='ES'?'inline':'none'}}>{data.allergy&&data.spicy?'-':''}</label>
                                            <label style={{display:lenguage==='ES'?'inline':'none'}}>{data.allergy&&data.vegan&&data.spicy===false?'-':''}</label>
                                            <label style={{fontFamily:'snell', fontSize:22, display:lenguage==='ES'?'inline':'none'}}> { data.allergy?'Alergico ':'' } </label>

                                            <label style={{fontFamily:'snell', fontSize:22, display:lenguage==='EN'?'inline':'none'}}> { data.vegan?'Vegan':'' } </label>
                                            <label style={{display:lenguage==='EN'?'inline':'none'}}>{data.vegan&&data.spicy?'-':''}</label>
                                            <label style={{fontFamily:'snell', fontSize:22, display:lenguage==='EN'?'inline':'none'}}> { data.spicy?'Spicy':'' } </label>
                                            <label style={{display:lenguage==='EN'?'inline':'none'}}>{data.allergy&&data.spicy?'-':''}</label>
                                            <label style={{display:lenguage==='EN'?'inline':'none'}}>{data.allergy&&data.vegan&&data.spicy===false?'-':''}</label>
                                            <label style={{fontFamily:'snell', fontSize:22, display:lenguage==='EN'?'inline':'none'}}> { data.allergy?'Allergy ':'' } </label>
                                        </div>
                                        <div style={{display:'flex', alignItems:'center', cursor:'pointer'}} onClick={()=>lenguage==='ES'?setLenguage('EN'):setLenguage('ES')}>
                                            <label style={{cursor:'pointer', fontFamily:'Lucida', fontWeight:lenguage==='ES'?600:500}}>{'ES'}</label>
                                            <label style={{cursor:'pointer', marginLeft:3, marginRight:3, fontFamily:'Lucida'}}>{'|'}</label>
                                            <label style={{cursor:'pointer', fontFamily:'Lucida', fontWeight:lenguage==='EN'?600:500}}>{'EN'}</label>
                                        </div>
                                    </div>
                                    <div className='description'>
                                        <p style={{margin:0, fontFamily:'Lucida'}}>
                                            { lenguage==='ES'? data.description : data.description2 }
                                        </p>
                                    </div>
                                    <div style={{height:10}} />

                                    <div style={{height:15}} />
                                    <div style={{display:'flex', justifyContent:'center'}}>
                                        <img alt='' src={Ic_instagram} style={{cursor:'pointer'}} onClick={()=>openInstragram()} />
                                        <div style={{width:20}} />
                                        <img alt='' src={Ic_whatsapp} style={{cursor:'pointer'}} onClick={openWhatsapp} />
                                    </div>
                                    <div style={{height:10}} />
                                </div>
                            </Container>
                        </div>
                    </Container>
            </Modal>
        </div>
    );
};

export default ModalAux;